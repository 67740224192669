import React, { useState } from 'react'
import * as styles from './nutrition-popup.module.scss'
import { useSiteQuery } from '../../../hooks'

const NutritionPopup = ({ fact }) => {
    const { nutritionTitle, fatTitle, carboHydateTitle, proteinTitle } = useSiteQuery()
    const [open, setOpen] = useState(false)
    const clickPopup = () => {
        let target = document.querySelectorAll(`.${styles.popover}`)
        if (!open) {
            target.forEach(element => {
                element.classList.add(styles.popoverOpen)
            })
            setOpen(!open)
        } else {
            target.forEach(element => {
                element.classList.remove(styles.popoverOpen)
            })
            setOpen(!open)
        }
    }
    return (
        <div>
            <div>
                <button className={styles.nutrition} onClick={() => clickPopup()}>
                    {nutritionTitle}
                </button>
                <div className={`${styles.popover}`}>
                    <div className={styles.closeBtn} onClick={() => clickPopup()}></div>
                    <div className={styles.arrow}></div>
                    <div className={styles.popoverContent}>
                        <div className={styles.factsBox}>
                            <h4>{nutritionTitle}</h4>
                            <p>
                                {
                                    fact?.header.split('\n').map((phrase, index) => (
                                        <span key={index}>{phrase}</span>
                                    ))
                                }
                            </p>
                        </div>
                        <table>
                            <tbody>
                                <tr className={`${styles.odd}`}>
                                    <th></th>
                                </tr>
                                <tr>
                                    <td>{fatTitle}</td>
                                    <td>{fact.fat}</td>
                                </tr>
                                <tr className={`${styles.odd}`}>
                                    <td>{carboHydateTitle}</td>
                                    <td>{fact.carb}</td>
                                </tr>
                                <tr>
                                    <td>{proteinTitle}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={styles.factsFooter}>
                            <p>
                                {fact?.footer.split('\n').map((phrase, index) => (
                                    <span key={index}>{phrase}</span>
                                ))}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NutritionPopup