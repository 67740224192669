import React from 'react'
import * as styles from './product-content.module.scss';

import { RichText } from '../../Shared/RichText/RichText';
import { useLocale, useSiteQuery } from '../../../hooks';
import { GatsbyImage } from 'gatsby-plugin-image';
import NutritionPopup from '../NutritionPopup/NutritionPopup';
const ProductContent = ({
    title,
    description,
    comments,
    fact
}) => {
    const { productCertifiedLogo, buyNowButton } = useSiteQuery()
    return (
        <div>
            <h1 className={styles.productTitle}>{title}</h1>
            <div className={styles.productContent}>
                <RichText content={description} />
                <p className={styles.comment}>
                    {
                        comments?.descriptionComments?.split('\n').map((phrase, index) => (
                            <span key={index} className={styles.phrase}>{phrase}</span>
                        ))
                    }
                </p>
            </div>
            <div className={styles.buttons}>
                {/* <div className={styles.buttonWrapper}>
                    <button>{buyNowButton}</button>
                </div> */}
                <div>
                    <GatsbyImage
                        image={productCertifiedLogo.gatsbyImageData}
                        alt={`Red Rose ${productCertifiedLogo.title}`}
                    />
                </div>
            </div>
            <div className={styles.nutritionContainer}>
                <NutritionPopup fact={fact} />
            </div>
        </div>
    )
}

export default ProductContent