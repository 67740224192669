import { graphql } from 'gatsby'
import React from 'react'
import ProductHero from '../components/Product/ProductHero/ProductHero'
import RelatedProducts from '../components/Product/RelatedProducts/RelatedProducts'
import Seo from '../components/Shared/Seo/Seo'
import { useLocale, useSchema } from '../hooks'
import * as styles from '../styles/local.module.scss'

const Product = ({
    location,
    pageContext,
    data: {
        contentfulPageProduct: {
            title,
            sku,
            slug,
            seoTitle,
            seoDescription,
            heroImage,
            imageUrl,
            description,
            createdAt,
            updatedAt,
            descriptionComments,
            relatedProductSection,
            breadCrumbs,
            factsHeader,
            factsFooter,
            fatField,
            carbohydrateField
        },
        allContentfulPageProduct: {
            nodes: allLinks
        },
        relatedProducts: {
            nodes
        }
    }
}) => {
    const locale = useLocale()
    const schemaDesc = description?.raw && JSON.parse(description.raw).content[0]?.content[0]?.value

    return (
        <div>
            <Seo
                title={seoTitle}
                description={seoDescription?.seoDescription}
                image={imageUrl}
                lang={locale}
                hrefLangs={allLinks}
                slug={slug}
                schemas={[
                    useSchema(
                        "breadcrumbs",
                        {
                            breadCrumbs: breadCrumbs,
                            current: {
                                title: title,
                                slug: slug
                            }
                        }
                    ),
                    useSchema(
                        "product",
                        {
                            slug: slug,
                            seoTitle: seoTitle,
                            sku: sku,
                            createdAt: createdAt,
                            image: imageUrl?.file?.url,
                            desc: schemaDesc
                        }
                    )
                ]}
            />
            <div className={styles.container}>
                <ProductHero
                    title={title}
                    heroImage={heroImage}
                    description={description}
                    descriptionComments={descriptionComments}
                    fact={{
                        header: factsHeader?.factsHeader,
                        footer: factsFooter?.factsFooter,
                        carb: carbohydrateField,
                        fat: fatField
                    }}
                />
                {
                    relatedProductSection?.products &&
                    <RelatedProducts data={relatedProductSection} products={nodes} />
                }
            </div>
        </div>
    )
}

export const ProductQuery = graphql`
query ProductPage($id: String!, $node_locale: String!, $contentful_id: String!){
    contentfulPageProduct(id: {eq: $id}){
        title
        seoTitle
        seoDescription {
            seoDescription
        }
        createdAt(locale: $node_locale, formatString: "MMMM DD, YYYY")
        updatedAt(locale: $node_locale, formatString: "MMMM DD, YYYY")
        slug
        sku
        imageUrl: heroImage {
            file {
                url
            }
            width
            height
            mimeType
        }
        heroImage {
            title
            gatsbyImageData(quality:90)
        }
        description {
            raw
        }
        descriptionComments {
            descriptionComments
        }
        relatedProductSection {
            title
            products {
                title
                slug
                heroImage {
                    title
                    gatsbyImageData
                }
            }
        }
        breadCrumbs {
            slug
            title
        }
        factsHeader {
            factsHeader
        }
        factsFooter {
            factsFooter
        }
        fatField
        carbohydrateField
    }
    allContentfulPageProduct(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}}){
        nodes {
            slug
            node_locale
        }
    }
    relatedProducts: allContentfulPageProduct(filter: {node_locale: {eq: $node_locale}, id: {ne: $id}}){
        nodes {
            title
            slug
            heroImage {
                title
                gatsbyImageData
            }
        }
    } 
}

`

export default Product