import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import ProductContent from '../ProductContent/ProductContent'
import ProductMobile from '../ProductMobile/ProductMobile'
import * as styles from './product-hero.module.scss'

const ProductHero = ({
    title,
    heroImage,
    description,
    descriptionComments,
    fact
}) => {
    return (
        <div>
            <div className={styles.productContainer}>
                <div className={styles.productImage}>
                    <div className={styles.imageContainer}>
                        <GatsbyImage
                            image={heroImage.gatsbyImageData}
                            alt={`Red Rose ${title}`}
                            className={styles.image}
                        />
                    </div>
                </div>
                <div className={styles.productContent}>
                    <ProductContent
                        title={title}
                        comments={descriptionComments}
                        description={description}
                        fact={fact}
                    />
                </div>
            </div>
            <div className={styles.mobileProductView}>
                <ProductMobile
                    title={title}
                    comments={descriptionComments}
                    description={description}
                    image={heroImage}
                    fact={fact}
                />
            </div>
        </div>
    )
}

export default ProductHero