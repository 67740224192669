import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { RichText } from '../../Shared/RichText/RichText'
import * as styles from './product-mobile.module.scss'
import { useSiteQuery } from '../../../hooks'
import NutritionPopup from '../NutritionPopup/NutritionPopup'
const ProductMobile = ({
    image,
    title,
    description,
    comments,
    fact
}) => {
    const { productCertifiedLogo, buyNowButton } = useSiteQuery()
    return (
        <div className={styles.mobileView}>
            <h2 className={styles.productTitle}>{title}</h2>
            <div className={styles.imageContainer}>
                <GatsbyImage image={image.gatsbyImageData} alt={`Red Rose ${title}`} />
            </div>
            <div className={styles.content}>
                <RichText content={description} />
                <p className={styles.comment}>
                    {
                        comments?.descriptionComments?.split('\n').map((phrase, index) => (
                            <span key={index} className={styles.phrase}>{phrase}</span>
                        ))
                    }
                </p>
                <GatsbyImage image={productCertifiedLogo.gatsbyImageData} alt={`Red Rose ${productCertifiedLogo.title}`} />
            </div>
            {/* <div className={styles.buttons}>
                <button>{buyNowButton}</button>
            </div> */}
            <NutritionPopup fact={fact} />
        </div>
    )
}

export default ProductMobile