import React, { useEffect, useRef } from 'react'
// import GridProducts from '../../Shared/GridProducts/GridProducts'
import * as styles from './related-products.module.scss'
import { useSiteQuery } from '../../../hooks'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";

// import { Navigation, Pagination } from "swiper";
import ProductCard from '../../Shared/ProductCard/ProductCard';

const RelatedProducts = ({
    data,
    products
}) => {
    const { relatedProducts } = useSiteQuery()
    // const { products } = data

    const option = {
        spaceBetween: 20,
        grabCursor: true,
        keyboard: {
            enabled: true
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
                navigation: false
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1.5,
                spaceBetween: 10,
                navigation: false
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 3,
                spaceBetween: 20
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 30
            }
        },
        slidesPerView: 3,
    }

    const swiperRef = useRef()

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    }

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    }

    useEffect(() => {
        // document.querySelector('.swiper-button-prev')?.classList.add(styles.arrow)
        // document.querySelector('.swiper-button-next')?.classList.add(styles.arrow)
        document.documentElement.style.setProperty("--swiper-navigation-size", "2rem")
        document.documentElement.style.setProperty("--swiper-navigation-color", "#90191c")
        document.documentElement.style.setProperty("--swiper-theme-color", "#90191c")
    }, [])

    return (
        <section className={styles.container}>
            <h4 className={styles.sectionTitle}>{relatedProducts}</h4>
            <div className={styles.products}>
                {/* <GridProducts data={data} /> */}
                <div className={`${styles.prevButton} ${styles.arrow}`} onClick={() => goPrev()}></div>
                <Swiper {...option} ref={swiperRef}>
                    {
                        products.map(({ heroImage, slug, title }, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <article className={styles.productCard}>
                                        <ProductCard heroImage={heroImage} slug={slug} title={title} />
                                    </article>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
                <div className={`${styles.nextButton} ${styles.arrow}`} onClick={() => goNext()}></div>
            </div>
        </section>
    )
}

export default RelatedProducts